<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>系统用户</template>
      <template v-slot:secondMenu>生成海报</template>
    </breadcrumb-nav>
    <el-card>
      <el-card class="box-card goods-add" shadow="never">
        <!--<el-button type="primary" size="small" @click="createPoster">生成海报</el-button>-->
        <el-button type="primary" size="small" @click="downloadPoster(posterImgURL,'微品海购邀请海报')">下载海报</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </el-card>
      <!--<div style="margin-top:15px;text-align: center">
        <el-button type="primary" size="small" @click="downloadPoster(posterImgURL,'微品海购邀请海报')">下载海报</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>-->
      <div style="padding: 20px">
        <!-- 海报html元素 -->
        <div id="posterHtml" class="posterBox">
          <img v-if="bgImgURL" :src="bgImgURL" class="bgImgBox" />
          <div  v-if="userAvatar" class="userDiv">
            <img :src="userAvatar" class="userAvatar"/>
            <div class="userNick">{{userName}}</div>
          </div>
          <div class="posterFooter">
            <div class="footerLeft">
              <img class="footerImg" src="../../assets/images/logo-small.png" />
            </div>
            <div class="footerRight">
              <!-- 二维码 -->
                <div id="qrcodeImg"></div>
                <div class="footerTextBox">
                  <div class="footerText">长按图片识别二维码</div>
                  <div class="footerText">查看图片详情</div>
                </div>
            </div>
          </div>
        </div>
        <!--微信里,可长按保存或转发-->
        <!--<img v-if="posterImgURL" class="posterBox" :src="posterImgURL" >-->
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { getRandShareConf } from '../../network/task'
import { getUserByIdRequest } from '../../network/user'
import { genShortChain } from '../../network/notify'
export default {
  name: 'QrCode',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      bgImgURL: '',
      contentData: '我是一张分享海报', // 文案内容
      posterImgURL: '', // 最终生成的海报图片URL
      userId: 0,
      userAvatar: '',
      userName: ''
    }
  },
  mounted () {
    this.userId = parseInt(this.$route.query.userId)
    getUserByIdRequest({ userId: this.userId }).then(res => {
      if (res.status !== 200) {
        return this.alertMessage('获取用户信息失败', 'error')
      }
      this.userAvatar = res.data.data.showAvatar
      if (!this.userAvatar.includes('http')) {
        this.userAvatar = window.g.MEDIA_DOWNLOAD_URL + this.userAvatar
      }
      this.userName = res.data.data.showName
    })
    getRandShareConf({ sence: 1 }).then(res => {
      if (res.status !== 200) {
        return this.alertMessage('获取分享海报失败', 'error')
      }
      this.bgImgURL = res.data.data.bigIcon
      if (!this.bgImgURL.includes('http')) {
        this.bgImgURL = window.g.MEDIA_DOWNLOAD_URL + this.bgImgURL
      }
    })
    this.$nextTick(
      () => {
        const lUrl = 'https://b.baihuiys.com/h5share/share.html?path=/pages/Main&inviter=' + this.userId + '&shareSence=systemInvite'
        genShortChain({ lUrl: lUrl }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('获取短链失败', 'error')
          }
          const sUrl = 'https://b.baihuiys.com/' + res.data.data.sUrl
          console.log('sUrl is:', sUrl)
          this.createQrcode(sUrl)
        })
      }
    )
  },
  methods: {
    // 生成二维码
    createQrcode (text) {
      const qrcodeImgEl = document.getElementById('qrcodeImg')
      qrcodeImgEl.innerHTML = ''
      const qrcode = new QRCode(qrcodeImgEl, {
        width: 52,
        height: 52,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(text)
    },
    // 生成海报
    downloadPoster () {
      const that = this
      const posterDOM = document.getElementById('posterHtml')
      html2canvas(posterDOM, {
        width: posterDOM.offsetWidth,
        height: posterDOM.offsetHeight,
        // 按比例增加分辨率
        scale: window.devicePixelRatio, // 设备像素比
        useCORS: true, // 图片跨域相关
        allowTaint: true, // 允许跨域（图片跨域相关）
        logging: false,
        letterRendering: true
      }).then(function (canvas) {
        that.posterImgURL = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        a.download = '微品海购推广二维码'
        a.href = that.posterImgURL
        a.dispatchEvent(new MouseEvent('click'))
      })
    },
    // 下载海报
    /* downloadPoster (url, fileName) {
      const a = document.createElement('a')
      a.download = fileName
      a.href = url
      a.dispatchEvent(new MouseEvent('click'))
    }, */
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
/*海报*/
.posterBox {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 330px;
  border: 1px solid #EBEEF5;
}

.bgImgBox {
  width: 330px;
  height: 500px;
}

/*海报文案*/
.posterContent {
  text-align: center;
  padding: 10px;
}

/*按钮*/
.myButton {
  background: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 10px;
}
.userDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.userAvatar {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.userNick {
  margin-left: 10px;
  color: #ff6338;
}
.posterFooter{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footerLeft {
  width: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.footerImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.footerRight {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footerTextBox {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.footerText {
  color: #606266;
}
</style>
